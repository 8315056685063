import React                              from 'react';
import { graphql, Link }                  from 'gatsby';
import { Box, Button, Center }            from '@chakra-ui/react'
import { GiDiamondRing, GiWatch, GiShop } from 'react-icons/gi';
import { BsTools }                        from 'react-icons/bs';
import { Md360 }                          from 'react-icons/md';
import Breakpoint                         from 'react-socks';

import Heading                   from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer                    from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper                   from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import RandomBrandHeaderImages
                                 from '@interness/brands-addon/src/components/media/RandomBrandHeaderImages/RandomBrandHeaderImages';
import ResponsiveIFrame          from '@interness/web-core/src/components/structure/iFrame/iFrame';
import LiveAnnouncements
                                 from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';
import CallToAction              from '@interness/theme-sonora/src/components/CallToAction/CallToAction';
import BrandsDisplay             from '@interness/theme-sonora/src/components/Display/BrandsDisplay';
import RandomShopItems           from '@interness/ecommerce-addon/src/components/RandomShopItems/RandomShopItems';
import RandomSquareGalleryImages from '@interness/brands-addon/src/components/media/RandomSquareGalleryImages';
import Mounted                   from '@interness/web-core/src/components/structure/Mounted/Mounted';
import Img                       from 'gatsby-image';
import FramedImage               from '@interness/theme-sonora/src/components/FramedImage';

const IndexPage = (props) => {
  return (
    <>
      <Mounted>
        <Breakpoint small down>
          <RandomSquareGalleryImages/>
        </Breakpoint>
        <Breakpoint medium up>
          <RandomBrandHeaderImages/>
        </Breakpoint>
      </Mounted>
      <Wrapper>
        <Center>
          <Button mt="-20px" colorScheme="brand" sx={{ textDecoration: 'none' }} as={Link} to="/terminvereinbarung">Termin gewünscht?</Button>
        </Center>
        <Spacer/>
        <LiveAnnouncements/>
        <section>
          <Heading subtitle={'Herzlich Willkommen'} icon={<GiDiamondRing/>}>Juwelier Münzer</Heading>
          <Spacer height={20}/>
          <span style={{ textAlign: 'justify' }}>
            <p>Seit mehr als 60 Jahren ist Juwelier Münzer ein Begriff für feine Uhren, traumhaften Schmuck und exklusive Trauringe und Eheringe. Seit 1999 führen Markus und Bettina Münzer das Geschäft am Luisenplatz in zweiter Generation.</p>
          <p>Gerne begrüßen wir Sie in unserem modernen Geschäft Luisenplatz 7 in Darmstadt
          und wünschen Ihnen ein wundervolles Einkauferlebnis.
          In unserem fein ausgewählten Sortiment finden Sie sowohl erlesenen als auch top aktuellen Schmuck, moderne und klassische Uhren, traumhafte Trauringe und Eheringe sowie extravagante Accessoires. Wir laden Sie herzlich ein, Ihre Lieblingsstücke auszuwählen, anzuprobieren und zu begutachten.
          </p>
          <p>Kompetente und persönliche Beratung rund um die Themen Schmuck, Uhren und bei Trauringen und Eheringen ist für das Team von Juwelier Münzer selbstverständlich.
          Wir bieten Ihnen perfekten Service und erstklassige Reparaturen.
          Ihre Lieblinge sind bei unserem Uhrmachermeister in unserer Meisterwerkstatt gut aufgeboben.<br/> 
          Wir kaufen Ihr Altgold zu fairen und tagesaktuellen Preisen an.
          </p>
          <p>Bis bald in Darmstadt.<br/>Ihre Familie Münzer.</p>
          </span>
        </section>
        <Spacer/>
        <section>
          <Heading tag={'h2'} icon={<GiWatch/>}>Aktuelle Highlights</Heading>
          <RandomShopItems/>
          <Spacer/>
          <Center>
            <Button sx={{ textDecoration: 'none' }} colorScheme="brand" as={Link} to="/schaufenster">Mehr Produkte
              entdecken</Button>
          </Center>
        </section>
        <Spacer/>
        <section>
          <Heading tag={'h2'} icon={<GiShop/>}>Neue Kollektionen entdecken</Heading>
          <Spacer/>
          <BrandsDisplay order={['engagement_rings', 'jewelry', 'watches']} exclude={['wedding_rings']}/>
        </section>
        <Spacer/>
        <section>
          <Heading tag={'h2'} icon={<BsTools/>}>Gutscheine</Heading>
          <Box maxW="640px" margin="auto">
            <FramedImage>
              <Img fluid={props.data.directusMediaCollection.media[0].file.localFile.childImageSharp.fluid}/>
            </FramedImage>
            <Spacer height={20}/>
            <Center>
              <p>Gutscheine von Juwelier Münzer erfüllen Herzenswünsche. Nutzen Sie unseren kostenlosen Service mit
                einem
                Betrag Ihrer Wahl.</p>
            </Center>
            <Center>
              <Button sx={{ textDecoration: 'none' }} colorScheme="brand" as={Link}
                      to="https://www.zmyle.de/co/checkout/1c7ib7cd32525eywvsz0">Zum Gutschein</Button>
            </Center>
          </Box>
        </section>
        <Spacer/>
        <section>
          <Heading tag={'h2'} icon={<Md360/>}>Virtueller Rundgang</Heading>
          <ResponsiveIFrame src="https://watch-my-city.de/darmstadt/juwelier-muenzer/?iframe=1"
                            title="Virtueller Rundgang" maxHeight={50}
                            consentNecessary={true}
                            consentHeader={'Virtuellen Rundgang anzeigen'}
                            consentText={'Zum Anzeigen unseres Rundgangs verwenden wir watch-my-city.de. Bitte beachten Sie, dass hierbei Ihre persönlichen Daten erfasst und gesammelt werden können. Unter anderem werden externe Inhalte von Google Servern geladen. Um den Rundgang zu sehen, stimmen Sie bitte zu, dass dieser von watch-my-city.de geladen wird.'}
                            consentButton={'Zustimmung: Rundgang laden'}
          />
        </section>
        <Spacer/>
        <CallToAction/>
      </Wrapper>

    </>
  )
};

export default IndexPage;

export const query = graphql`
    query {
        directusMediaCollection(name: {eq: "coupon"}) {
            media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 700, maxHeight: 274, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        partner: directusMediaCollection(name: {eq: "partner"}) {
            media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 700, maxHeight: 274, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;
